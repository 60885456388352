import { useAccountContext } from '../contexts/AccountContext';
import AccountsService from '../services/accounts/AccountsService';
import { LoginData, RegisterData } from '../types/types';
import {IUser} from "../models/interfaces";

export const useAuth = () => {
    const { state, dispatch } = useAccountContext();

    const login = async (loginData: LoginData) => {
        dispatch({ type: 'LOGIN_START' });
        try {
            const user = await AccountsService.login(loginData);
            dispatch({ type: 'LOGIN_SUCCESS', payload: user });
            return user;
        } catch (error) {
            dispatch({ type: 'LOGIN_ERROR', payload: 'Login failed' });
            throw error;
        }
    };

    const register = async (registerData: RegisterData) => {
        dispatch({ type: 'REGISTER_START' });
        try {
             await AccountsService.register(registerData);
            dispatch({ type: 'REGISTER_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'REGISTER_ERROR', payload: 'Registration failed' });
            throw error;
        }
    };

    const logout = async () => {
        await AccountsService.logout();
        dispatch({ type: 'LOGOUT' });
    };

    const updateUser = (user: IUser) => {
        dispatch({ type: 'UPDATE_USER', payload: user });
    };

    return {
        user: state.user,
        isLoading: state.isLoading,
        error: state.error,
        isAuthenticated: state.isAuthenticated,
        login,
        register,
        logout,
        updateUser,
    };
};