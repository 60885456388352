import React from 'react';

interface LoadingSpinnerProps {
    size?: 'small' | 'medium' | 'large';
    color?: string;
    text?: string;
    fullScreen?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
                                                           size = 'medium',
                                                           color = 'text-primary',
                                                           text = 'Chargement...',
                                                           fullScreen = false
                                                       }) => {
    const sizeClasses = {
        small: 'w-4 h-4',
        medium: 'w-8 h-8',
        large: 'w-12 h-12'
    };

    const containerClasses = fullScreen
        ? 'fixed inset-0 flex items-center justify-center bg-background bg-opacity-75 z-50'
        : 'flex items-center justify-center h-full';

    return (
        <div className={containerClasses} role="status">
            <div className="flex flex-col items-center">
                <svg
                    className={`animate-spin ${sizeClasses[size]} ${color}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                {text && (
                    <span className={`mt-2 ${size === 'small' ? 'text-sm' : 'text-base'} ${color}`}>
                        {text}
                    </span>
                )}
            </div>
        </div>
    );
};

export default LoadingSpinner;