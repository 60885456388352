import React, { ErrorInfo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { AlertTriangle, RefreshCw, Home } from 'lucide-react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    console.error("Uncaught error:", error, errorInfo);
    // Ici, vous pourriez envoyer l'erreur à un service de reporting
    // logErrorToService(error, errorInfo);
  }

  private handleReload = () => {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
          <div className="min-h-screen bg-background flex items-center justify-center px-4">
            <div className="bg-surface rounded-lg shadow-elevation-3 p-8 max-w-md w-full text-center">
              <AlertTriangle size={64} className="text-error mx-auto mb-4" />
              <h1 className="text-headline-medium text-primary mb-4">Oups ! Une erreur est survenue</h1>
              <p className="text-body-medium text-on-surface-variant mb-6">
                Nous sommes désolés, mais quelque chose s'est mal passé. Notre équipe a été notifiée et travaille à résoudre le problème.
              </p>
              {process.env.NODE_ENV === 'development' && this.state.error && (
                  <div className="bg-surface-variant rounded p-4 mb-6 text-left">
                    <p className="text-body-small text-error mb-2">{this.state.error.toString()}</p>
                    <pre className="text-body-small text-on-surface-variant overflow-auto max-h-40">
                  {this.state.errorInfo?.componentStack}
                </pre>
                  </div>
              )}
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <button
                    onClick={this.handleReload}
                    className="bg-primary text-on-primary py-2 px-4 rounded-full text-label-large hover:bg-primary-dark transition-colors duration-300 flex items-center justify-center"
                >
                  <RefreshCw size={20} className="mr-2" />
                  Réessayer
                </button>
                <Link
                    to="/"
                    className="bg-surface text-primary border border-primary py-2 px-4 rounded-full text-label-large hover:bg-surface-variant transition-colors duration-300 flex items-center justify-center"
                >
                  <Home size={20} className="mr-2" />
                  Retour à l'accueil
                </Link>
              </div>
            </div>
          </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;