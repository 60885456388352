import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/ui/LoadingSpinner';
import PrivateRoute from "./routes/components/PrivateRoute";
import { AccountProvider, useAccountContext } from "./contexts/AccountContext";
import { ParcelProvider } from "./contexts/ParcelContext";
import { ShipmentProvider } from "./contexts/ShipmentContext";
import { TooltipProvider } from "./components/ui/Tooltip";
import { ToastProvider } from "./hooks/useToast";

// Lazy-loaded route components
const PublicRoutes = React.lazy(() => import('./routes/PublicRoutes'));
const ClientDashboardRoutes = React.lazy(() => import('./routes/ClientDashboardRoutes'));
const DashboardRoutes = React.lazy(() => import('./routes/DashboardRoutes'));

// Create a client for React Query
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: Infinity, // Add this line
        },
        mutations: {
            onError: (error) => {
                // Handle mutation errors globally if needed
                console.error('Mutation error:', error);
            },
        },
    },
});

// AppProviders component to wrap the app with all necessary providers
const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <Router>
            <AccountProvider>
                <ParcelProvider>
                    <ShipmentProvider>
                        <TooltipProvider>
                            <ToastProvider>
                                <ErrorBoundary>
                                    {children}
                                </ErrorBoundary>
                            </ToastProvider>
                        </TooltipProvider>
                    </ShipmentProvider>
                </ParcelProvider>
            </AccountProvider>
        </Router>
        <Toaster position="top-right" />
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

// AppRoutes component to define the application routes
const AppRoutes: React.FC = () => {
    const { state } = useAccountContext();

    if (state.isLoading && false) {
        return <LoadingSpinner />;
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="/*" element={<PublicRoutes />} />
                <Route
                    path="/application/*"
                    element={
                        <PrivateRoute>
                            <ClientDashboardRoutes />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRoute role="staff">
                            <DashboardRoutes />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
        </Suspense>
    );
};

// Main App component
const App: React.FC = () => {
    return (
        <AppProviders>
            <AppRoutes />
        </AppProviders>
    );
};

export default App;