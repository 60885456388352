import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface PrivateRouteProps {
    children: React.ReactNode;
    role?: 'admin' | 'staff';
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, role }) => {
    const { user, isAuthenticated, isLoading } = useAuth();
    const location = useLocation();

    if (isLoading) {
        return <div>Loading...</div>; // Or a loading spinner component
    }
    if (!isAuthenticated || !user) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />;
    }


    if (role === 'staff' && !user.is_staff) {
        return <Navigate to="/unauthorized" replace />;
    }

    if (role === 'admin' && !user.is_admin) {
        return <Navigate to="/unauthorized" replace />;
    }


    return <>{children}</>;
};

export default PrivateRoute;