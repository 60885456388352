import React, { createContext, useContext, useReducer, ReactNode, useCallback, useEffect } from 'react';
import {  LoginData, RegisterData } from '../types/types';
import AccountsService from "../services/accounts/AccountsService";
import {IUser} from "../models/interfaces";

interface AccountState {
    user: IUser | null;
    isLoading: boolean;
    error: string | null;
    isAuthenticated: boolean;
}

type AccountAction =
    | { type: 'LOGIN_START' }
    | { type: 'LOGIN_SUCCESS'; payload: IUser }
    | { type: 'LOGIN_ERROR'; payload: string }
    | { type: 'LOGOUT' }
    | { type: 'UPDATE_USER'; payload: IUser }
    | { type: 'REGISTER_START' }
    | { type: 'REGISTER_SUCCESS'}
    | { type: 'REGISTER_ERROR'; payload: string }
    | { type: 'CLEAR_ERROR' }
    | { type: 'SET_LOADING'; payload: boolean };

interface AccountContextType {
    state: AccountState;
    dispatch: React.Dispatch<AccountAction>;
    login: (data: LoginData) => Promise<void>;
    register: (data: RegisterData) => Promise<void>;
    logout: () => Promise<void>;
    updateUser: (user: IUser) => void;
    clearError: () => void;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

const initialState: AccountState = {
    user: null,
    isLoading: true,
    error: null,
    isAuthenticated: false,
};

function accountReducer(state: AccountState, action: AccountAction): AccountState {
    switch (action.type) {
        case 'LOGIN_START':
        case 'REGISTER_START':
            return { ...state, isLoading: true, error: null };
        case 'LOGIN_SUCCESS':
            localStorage.setItem('user', JSON.stringify(action.payload));
            return { ...state, isLoading: false, user: action.payload, error: null, isAuthenticated: true };
        case 'REGISTER_SUCCESS':
            return { ...initialState, isLoading: false };
        case 'LOGIN_ERROR':
        case 'REGISTER_ERROR':
            return { ...state, isLoading: false, error: action.payload, isAuthenticated: false };
        case 'LOGOUT':
            localStorage.removeItem('user');
            return { ...initialState, isLoading: false };
        case 'UPDATE_USER':
            localStorage.setItem('user', JSON.stringify(action.payload));
            return { ...state, user: action.payload };
        case 'CLEAR_ERROR':
            return { ...state, error: null };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

export const AccountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const initializeAuth = async () => {
            dispatch({ type: 'SET_LOADING', payload: true });
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                try {
                    const user = await AccountsService.getCurrentUser();
                    dispatch({ type: 'LOGIN_SUCCESS', payload: user });
                } catch (error) {
                    console.error('Failed to validate stored user:', error);
                    localStorage.removeItem('user');
                    dispatch({ type: 'LOGOUT' });
                }
            } else {
                dispatch({ type: 'SET_LOADING', payload: false });
            }
        };

        initializeAuth();
    }, []);

    const login = useCallback(async (data: LoginData) => {
        dispatch({ type: 'LOGIN_START' });
        try {
            const user = await AccountsService.login(data);
            dispatch({ type: 'LOGIN_SUCCESS', payload: user });
        } catch (error) {
            dispatch({ type: 'LOGIN_ERROR', payload: error instanceof Error ? error.message : 'An unknown error occurred' });
        }
    }, []);

    const register = useCallback(async (data: RegisterData) => {
        dispatch({ type: 'REGISTER_START' });
        try {
       await AccountsService.register(data);
            dispatch({ type: 'REGISTER_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'REGISTER_ERROR', payload: error instanceof Error ? error.message : 'An unknown error occurred' });
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            await AccountsService.logout();
            dispatch({ type: 'LOGOUT' });
        } catch (error) {
            console.error('Logout failed:', error);
            // Even if the server-side logout fails, we still want to clear the local state
            dispatch({ type: 'LOGOUT' });
        }
    }, []);

    const updateUser = useCallback((user: IUser) => {
        dispatch({ type: 'UPDATE_USER', payload: user });
    }, []);

    const clearError = useCallback(() => {
        dispatch({ type: 'CLEAR_ERROR' });
    }, []);

    return (
        <AccountContext.Provider value={{ state, dispatch, login, register, logout, updateUser, clearError }}>
            {children}
        </AccountContext.Provider>
    );
};

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (context === undefined) {
        throw new Error('useAccountContext must be used within an AccountProvider');
    }
    return context;
};

// Helper hooks
export const useUser = () => {
    const { state } = useAccountContext();
    return state.user;
};

export const useIsAuthenticated = () => {
    const { state } = useAccountContext();
    return state.isAuthenticated;
};