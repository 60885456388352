import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import {IParcel} from "../models/interfaces";

interface ParcelState {
    parcels: IParcel[];
    isLoading: boolean;
    error: string | null;
}

type ParcelAction =
    | { type: 'FETCH_PARCELS_START' }
    | { type: 'FETCH_PARCELS_SUCCESS'; payload: IParcel[] }
    | { type: 'FETCH_PARCELS_ERROR'; payload: string }
    | { type: 'CREATE_PARCEL_START' }
    | { type: 'CREATE_PARCEL_SUCCESS'; payload: IParcel }
    | { type: 'CREATE_PARCEL_ERROR'; payload: string }
    | { type: 'UPDATE_PARCEL_START' }
    | { type: 'UPDATE_PARCEL_SUCCESS'; payload: IParcel }
    | { type: 'UPDATE_PARCEL_ERROR'; payload: string }
    | { type: 'DELETE_PARCEL_START' }
    | { type: 'DELETE_PARCEL_SUCCESS'; payload: string }
    | { type: 'DELETE_PARCEL_ERROR'; payload: string };

const ParcelContext = createContext<
    { state: ParcelState; dispatch: React.Dispatch<ParcelAction> } | undefined
>(undefined);

const initialState: ParcelState = {
    parcels: [],
    isLoading: false,
    error: null,
};

function parcelReducer(state: ParcelState, action: ParcelAction): ParcelState {
    switch (action.type) {
        case 'FETCH_PARCELS_START':
        case 'CREATE_PARCEL_START':
        case 'UPDATE_PARCEL_START':
        case 'DELETE_PARCEL_START':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_PARCELS_SUCCESS':
            return { ...state, isLoading: false, parcels: action.payload };
        case 'CREATE_PARCEL_SUCCESS':
            return { ...state, isLoading: false, parcels: [...state.parcels, action.payload] };
        case 'UPDATE_PARCEL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                parcels: state.parcels.map(p => p.id === action.payload.id ? action.payload : p),
            };
        case 'DELETE_PARCEL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                parcels: state.parcels.filter(p => p.id !== action.payload),
            };
        case 'FETCH_PARCELS_ERROR':
        case 'CREATE_PARCEL_ERROR':
        case 'UPDATE_PARCEL_ERROR':
        case 'DELETE_PARCEL_ERROR':
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}

export const ParcelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(parcelReducer, initialState);

    return (
        <ParcelContext.Provider value={{ state, dispatch }}>
            {children}
        </ParcelContext.Provider>
    );
};

export const useParcelContext = () => {
    const context = useContext(ParcelContext);
    if (context === undefined) {
        throw new Error('useParcelContext must be used within a ParcelProvider');
    }
    return context;
};