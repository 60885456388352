import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const API_URL = 'https://lawa-transit.fr/api/v1';

class ApiService {
    private api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.api.interceptors.request.use(this.handleRequestUse);
        this.api.interceptors.response.use(
            (response) => response,
            this.handleResponseError
        );
    }

    private handleRequestUse = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers = config.headers || {};
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    };

    private handleResponseError = async (error: any) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post<{ access: string }>(`${API_URL}/accounts/auth/token/refresh/`, {
                    refresh: refreshToken,
                });
                const newAccessToken = response.data.access;
                localStorage.setItem('accessToken', newAccessToken);
                this.api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                return this.api(originalRequest);
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                // Redirect to login or handle token refresh failure
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    };

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.api.get(url, config)
        return response.data;
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.api.post(url, data, config);
        return response.data;
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.api.put(url, data, config);
        return response.data;
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.api.delete(url, config);
        return response.data;
    }

    public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.api.patch(url, data, config);
        return response.data;
    }
}

export default new ApiService();