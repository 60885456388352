import {
    LoginData,
    LoginResponse,
    RegisterData,
    PasswordChangeData,
    PasswordResetData,
    PasswordResetConfirmData,
    ResendEmailVerificationData,
    PaginatedUserResponse,
    FilterOptions
} from '../../types/types';
import ApiService from "../api/ApiService";
import {IUser} from "../../models/interfaces";

class AccountsService {
    private handleError(error: any, message: string): never {
        console.error(message, error);
        throw error;
    }

    async login(data: LoginData): Promise<IUser> {
        try {
            const loginResponse = await ApiService.post<LoginResponse>('/accounts/auth/login/', data);
            localStorage.setItem('accessToken', loginResponse.access);
            localStorage.setItem('refreshToken', loginResponse.refresh);
            return this.getCurrentUser();
        } catch (error) {
            this.handleError(error, 'Login failed:');
        }
    }

    async logout(): Promise<void> {
        try {
            await ApiService.post('/accounts/auth/logout/');
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    }

    async register(data: RegisterData): Promise<IUser> {
        try {
            return await ApiService.post<IUser>('/accounts/auth/register/', data);
        } catch (error) {
            this.handleError(error, 'Registration failed:');
        }
    }

    async getCurrentUser(): Promise<IUser> {
        try {
            return await ApiService.get<IUser>('/accounts/users/me/');
        } catch (error) {
            this.handleError(error, 'Failed to get current user:');
        }
    }

    async updateCurrentUser(data: Partial<IUser>): Promise<IUser> {
        try {
            return await ApiService.put<IUser>('/accounts/users/me/', data);
        } catch (error) {
            this.handleError(error, 'Failed to update user:');
        }
    }

    async changePassword(data: PasswordChangeData): Promise<void> {
        try {
            await ApiService.post('/accounts/auth/password/change/', data);
        } catch (error) {
            this.handleError(error, 'Failed to change password:');
        }
    }

    async resetPassword(data: PasswordResetData): Promise<void> {
        try {
            await ApiService.post('/accounts/password/reset/', data);
        } catch (error) {
            this.handleError(error, 'Failed to reset password:');
        }
    }

    async confirmResetPassword(data: PasswordResetConfirmData): Promise<void> {
        try {
            await ApiService.post('/accounts/password/reset/confirm/', data);
        } catch (error) {
            this.handleError(error, 'Failed to confirm password reset:');
        }
    }

    async resendVerificationEmail(data: ResendEmailVerificationData): Promise<void> {
        try {
            await ApiService.post('/accounts/email/register/resend-email/', data);
        } catch (error) {
            this.handleError(error, 'Failed to resend verification email:');
        }
    }

    async updateUserPhoto(photoFile: File): Promise<IUser> {
        try {
            const formData = new FormData();
            formData.append('photo', photoFile);
            return await ApiService.put<IUser>('/accounts/users/update_photo/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            this.handleError(error, 'Failed to update user photo:');
        }
    }

    async getUserStats(): Promise<any> {
        try {
            return await ApiService.get('/accounts/users/stats/');
        } catch (error) {
            this.handleError(error, 'Failed to get user stats:');
        }
    }

    async getAllUsers(filters?: FilterOptions): Promise<PaginatedUserResponse> {
        try {
            return await ApiService.get<PaginatedUserResponse>('/accounts/users/', { params: filters });
        } catch (error) {
            this.handleError(error, 'Failed to get all users:');
        }
    }

    async getUser(userId: string): Promise<IUser> {
        try {
            return await ApiService.get<IUser>(`/accounts/users/${userId}/`);
        } catch (error) {
            this.handleError(error, `Failed to get user with id ${userId}:`);
        }
    }

    async createUser(userData: RegisterData): Promise<IUser> {
        try {
            return await ApiService.post<IUser>('/accounts/users/', userData);
        } catch (error) {
            this.handleError(error, 'Failed to create new user:');
        }
    }

    async updateUser(userId: string, userData: Partial<IUser>): Promise<IUser> {
        try {
            return await ApiService.put<IUser>(`/accounts/users/${userId}/`, userData);
        } catch (error) {
            this.handleError(error, `Failed to update user with id ${userId}:`);
        }
    }

    async deleteUser(userId: string): Promise<void> {
        try {
            await ApiService.delete(`/accounts/users/${userId}/`);
        } catch (error) {
            this.handleError(error, `Failed to delete user with id ${userId}:`);
        }
    }

    async getUsersStats(): Promise<any> {
        try {
            return await ApiService.get('/accounts/users/stats/');
        } catch (error) {
            this.handleError(error, 'Failed to get users stats:');
        }
    }


}

export default new AccountsService();