import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import {IShipment} from "../models/interfaces";

interface ShipmentState {
    shipments: IShipment[];
    isLoading: boolean;
    error: string | null;
}

type ShipmentAction =
    | { type: 'FETCH_SHIPMENTS_START' }
    | { type: 'FETCH_SHIPMENTS_SUCCESS'; payload: IShipment[] }
    | { type: 'FETCH_SHIPMENTS_ERROR'; payload: string }
    | { type: 'CREATE_SHIPMENT_START' }
    | { type: 'CREATE_SHIPMENT_SUCCESS'; payload: IShipment }
    | { type: 'CREATE_SHIPMENT_ERROR'; payload: string }
    | { type: 'UPDATE_SHIPMENT_START' }
    | { type: 'UPDATE_SHIPMENT_SUCCESS'; payload: IShipment }
    | { type: 'UPDATE_SHIPMENT_ERROR'; payload: string }
    | { type: 'DELETE_SHIPMENT_START' }
    | { type: 'DELETE_SHIPMENT_SUCCESS'; payload: string }
    | { type: 'DELETE_SHIPMENT_ERROR'; payload: string };

const ShipmentContext = createContext<
    { state: ShipmentState; dispatch: React.Dispatch<ShipmentAction> } | undefined
>(undefined);

const initialState: ShipmentState = {
    shipments: [],
    isLoading: false,
    error: null,
};

function shipmentReducer(state: ShipmentState, action: ShipmentAction): ShipmentState {
    switch (action.type) {
        case 'FETCH_SHIPMENTS_START':
        case 'CREATE_SHIPMENT_START':
        case 'UPDATE_SHIPMENT_START':
        case 'DELETE_SHIPMENT_START':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_SHIPMENTS_SUCCESS':
            return { ...state, isLoading: false, shipments: action.payload };
        case 'CREATE_SHIPMENT_SUCCESS':
            return { ...state, isLoading: false, shipments: [...state.shipments, action.payload] };
        case 'UPDATE_SHIPMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipments: state.shipments.map(s => s.id === action.payload.id ? action.payload : s),
            };
        case 'DELETE_SHIPMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipments: state.shipments.filter(s => s.id !== action.payload),
            };
        case 'FETCH_SHIPMENTS_ERROR':
        case 'CREATE_SHIPMENT_ERROR':
        case 'UPDATE_SHIPMENT_ERROR':
        case 'DELETE_SHIPMENT_ERROR':
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}

export const ShipmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(shipmentReducer, initialState);

    return (
        <ShipmentContext.Provider value={{ state, dispatch }}>
            {children}
        </ShipmentContext.Provider>
    );
};

export const useShipmentContext = () => {
    const context = useContext(ShipmentContext);
    if (context === undefined) {
        throw new Error('useShipmentContext must be used within a ShipmentProvider');
    }
    return context;
};