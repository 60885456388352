import React from 'react';
import { X, CheckCircle, AlertCircle, Info, AlertTriangle } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';

export interface ToastProps {
    id: string;
    title: string;
    description?: string;
    variant: 'success' | 'error' | 'info' | 'warning' | 'destructive';
    onClose: () => void;
}

const variantStyles = {
    success: 'bg-green-100 border-green-500 text-green-800',
    error: 'bg-red-100 border-red-500 text-red-800',
    info: 'bg-blue-100 border-blue-500 text-blue-800',
    warning: 'bg-yellow-100 border-yellow-500 text-yellow-800',
    destructive: 'bg-red-100 border-red-500 text-red-800',
};

const variantIcons = {
    success: CheckCircle,
    error: AlertCircle,
    info: Info,
    warning: AlertTriangle,
    destructive: AlertCircle,
};

export const Toast: React.FC<ToastProps> = ({ id, title, description, variant, onClose }) => {
    const Icon = variantIcons[variant];

    return (
        <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            className={cn(
                "max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex overflow-hidden",
                "border-l-4",
                variantStyles[variant]
            )}
        >
            <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                        <Icon className="h-5 w-5" />
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="text-sm font-medium">{title}</p>
                        {description && (
                            <p className="mt-1 text-sm opacity-90">{description}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex border-l border-gray-200">
                <button
                    onClick={onClose}
                    className="flex items-center justify-center w-full p-4 text-sm font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition-colors duration-200"
                >
                    <span className="sr-only">Close</span>
                    <X className="h-5 w-5" />
                </button>
            </div>
        </motion.div>
    );
};

export default Toast;